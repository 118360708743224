import {
  PresentationProperty,
  PresentationPropertyFileType,
  Constraints,
  Strings,
  OptionsUrlHttpHeader,
} from '@raydiant/api-client-js';

export const getPropertyType = (property: PresentationProperty) => {
  const typeMapping: Record<string, string> = {
    string: 'text',
    boolean: 'toggle',
    selection: 'select',
    selection2: 'select',
    toggleButtonGroup: 'button group',
    oAuth2: 'oauth',
    catalog: 'product catalog',
  };

  if (
    (property.type === 'selection' || property.type === 'selection2') &&
    property.multiple
  ) {
    return 'multi select';
  }

  return typeMapping[property.type] || property.type;
};

export const getPropertyName = (
  property: PresentationProperty,
  strings: Strings | null | undefined,
) => {
  return strings?.en?.[property.name] ?? property.name;
};

export const setPropertyName = (
  property: PresentationProperty,
  strings: Strings,
  name: string,
): [PresentationProperty, Strings] => {
  const updatedStrings = {
    en: {
      ...strings.en,
      [getPropertyId(property)]: name,
    },
  };

  return [property, updatedStrings];
};

export const getPropertyId = (property: PresentationProperty) => {
  return property.name;
};

export const getHelperTextKey = (property: PresentationProperty) =>
  `${getPropertyId(property)}_helperText`;

export const setPropertyId = (
  property: PresentationProperty,
  strings: Strings,
  id: string,
): [PresentationProperty, Strings] => {
  const prevPropertyId = getPropertyId(property);
  const didPropertyIdChange = id !== prevPropertyId;

  if (!didPropertyIdChange) return [property, strings];

  const updatedProperty: PresentationProperty = {
    ...property,
    name: id,
  };

  const updatedStrings: Strings = { en: { ...strings.en } };

  // Set input name string for new id and delete the old id.
  if (updatedStrings.en[prevPropertyId]) {
    updatedStrings.en[id] = updatedStrings.en[prevPropertyId];
    delete updatedStrings.en[prevPropertyId];
  }

  return [updatedProperty, updatedStrings];
};

export const isPropertyRequired = (property: PresentationProperty) => {
  return property.optional !== true;
};

export const setPropertyRequired = (
  property: PresentationProperty,
  isRequired: boolean,
): PresentationProperty => {
  return {
    ...property,
    optional: !isRequired,
  };
};

export const isPropertyMultilineText = (property: PresentationProperty) => {
  return property.type === 'text';
};

export const setPropertyMultilineText = (
  property: PresentationProperty,
  isMultiline: boolean,
): PresentationProperty => {
  return {
    ...property,
    type: isMultiline ? 'text' : 'string',
  };
};

export const setPropertyConstraint = (
  property: PresentationProperty,
  key: string,
  value: any,
): PresentationProperty => ({
  ...property,
  constraints: {
    ...(property.constraints || {}),
    [key]: value,
  },
});

export const removePropertyConstraint = (
  property: PresentationProperty,
  key: string,
): PresentationProperty => {
  const updatedProperty = {
    ...property,
  };

  if (!updatedProperty.constraints) return updatedProperty;
  delete updatedProperty.constraints[key as keyof Constraints];
  return updatedProperty;
};

export const getPropertyMaxLength = (
  property: PresentationProperty,
): number | undefined => {
  return property.constraints?.maxlength;
};

export const setPropertyMaxLength = (
  property: PresentationProperty,
  maxLength: number | null,
) => {
  if (maxLength === null) {
    return removePropertyConstraint(property, 'maxlength');
  }
  return setPropertyConstraint(property, 'maxlength', maxLength);
};

export const getPropertyHelperText = (
  property: PresentationProperty,
): string | undefined => {
  return property.helper_text;
};

export const setPropertyHelperText = (
  property: PresentationProperty,
  helperText: string,
): PresentationProperty => {
  return {
    ...property,
    helper_text: helperText,
  };
};

export const getPropertyAuthUrl = (
  property: PresentationProperty,
): string | undefined => {
  return property.auth_url;
};

export const setPropertyAuthUrl = (
  property: PresentationProperty,
  authUrl: string,
): PresentationProperty => {
  return {
    ...property,
    auth_url: authUrl,
  };
};

export const getPropertyVerifyUrl = (
  property: PresentationProperty,
): string | undefined => {
  return property.verify_url;
};

export const setPropertyVerifyUrl = (
  property: PresentationProperty,
  verifyUrl: string,
): PresentationProperty => {
  return {
    ...property,
    verify_url: verifyUrl,
  };
};

export const getPropertyLogoutUrl = (
  property: PresentationProperty,
): string | undefined => {
  return property.logout_url;
};

export const setPropertyLogoutUrl = (
  property: PresentationProperty,
  logoutUrl: string,
): PresentationProperty => {
  return {
    ...property,
    logout_url: logoutUrl,
  };
};

export const getPropertyVerifyQsParam = (
  property: PresentationProperty,
): string | undefined => {
  return property.verify_qs_param;
};

export const setPropertyVerifyQsParam = (
  property: PresentationProperty,
  verifyQsParam: string,
): PresentationProperty => {
  return {
    ...property,
    verify_qs_param: verifyQsParam,
  };
};

export const getPropertyLogoutQsParam = (
  property: PresentationProperty,
): string | undefined => {
  return property.logout_qs_param;
};

export const setPropertyLogoutQsParam = (
  property: PresentationProperty,
  logoutQsParam: string,
): PresentationProperty => {
  return {
    ...property,
    logout_qs_param: logoutQsParam,
  };
};

export const getPropertyHelperLink = (
  property: PresentationProperty,
): string | undefined => {
  return property.helper_link;
};

export const setPropertyHelperLink = (
  property: PresentationProperty,
  helperLink: string,
): PresentationProperty => {
  return {
    ...property,
    helper_link: helperLink,
  };
};

export interface PropertyOption {
  label: string;
  value: string;
}

export const getPropertyOptions = (
  property: PresentationProperty,
  strings: Strings,
): PropertyOption[] => {
  if (!property.options) return [];
  return property.options.map((opt) => ({
    // Legacy selection types may still use `name` instead of `label`.
    label: (opt.name ? strings.en[opt.name] || opt.name : opt.label) || '',
    value: opt.value,
  }));
};

export const getPropertyOptionsUrlHttpHeaders = (
  property: PresentationProperty,
  strings: Strings,
): OptionsUrlHttpHeader[] => {
  if (!property.options_url_http_headers) return [];

  return property.options_url_http_headers.map((h) => ({
    key: h.key,
    value: h.value,
  }));
};

export const getPropertyOptionsUrl = (
  property: PresentationProperty | null,
): string | undefined => {
  return property?.options_url;
};

export const setPropertyOptions = (
  property: PresentationProperty,
  options: PropertyOption[],
) => {
  const updatedProperty: PresentationProperty = {
    ...property,
    options_url: undefined,
    options_url_http_headers: undefined,
    options: [],
  };

  options.forEach((opt) => {
    updatedProperty.options?.push({ label: opt.label, value: opt.value });
  });

  return updatedProperty;
};

export const setPropertyOptionsUrlHttpHeaders = (
  property: PresentationProperty,
  httpHeaders: PropertyOption[],
) => {
  const updatedProperty: PresentationProperty = {
    ...property,
    options: undefined,
    default: undefined,
    options_url_http_headers: [],
  };

  httpHeaders.forEach((h) => {
    updatedProperty.options_url_http_headers?.push({
      key: h.label,
      value: h.value,
    });
  });

  return updatedProperty;
};

export const setPropertyOptionsUrl = (
  property: PresentationProperty,
  value: string,
) => {
  const updatedProperty: PresentationProperty = {
    ...property,
    options: undefined,
    default: undefined,
    options_url: value,
  };

  return updatedProperty;
};

export const getPropertyDefault = (property: PresentationProperty) => {
  return property.default;
};

export const setPropertyDefault = (
  property: PresentationProperty,
  defaultValue: any,
): PresentationProperty => {
  return {
    ...property,
    default: defaultValue,
  };
};

export const getPropertyMin = (property: PresentationProperty) => {
  return property.constraints?.min;
};

export const setPropertyMin = (
  property: PresentationProperty,
  min: number | null,
): PresentationProperty => {
  if (min === null) {
    return removePropertyConstraint(property, 'min');
  }
  return setPropertyConstraint(property, 'min', min);
};

export const getPropertyMax = (property: PresentationProperty) => {
  return property.constraints?.max;
};

export const setPropertyMax = (
  property: PresentationProperty,
  max: number | null,
): PresentationProperty => {
  if (max === null) {
    return removePropertyConstraint(property, 'max');
  }
  return setPropertyConstraint(property, 'max', max);
};

export const getPropertyBackgroundColor = (property: PresentationProperty) => {
  return property.background_color;
};

export const setPropertyBackgroundColor = (
  property: PresentationProperty,
  backgroundColor: string | null,
) => {
  const updatedProperty = { ...property };

  if (backgroundColor) {
    updatedProperty.background_color = backgroundColor;
  } else {
    delete updatedProperty.background_color;
  }

  return updatedProperty;
};

export const getPropertyForegroundColor = (property: PresentationProperty) => {
  return property.foreground_color;
};

export const setPropertyForegroundColor = (
  property: PresentationProperty,
  foregroundColor: string | null,
) => {
  const updatedProperty = { ...property };

  if (foregroundColor) {
    updatedProperty.foreground_color = foregroundColor;
  } else {
    delete updatedProperty.foreground_color;
  }

  return updatedProperty;
};

export const getPropertyIconUrl = (property: PresentationProperty) => {
  return property.icon_url;
};

export const setPropertyIconUrl = (
  property: PresentationProperty,
  iconUrl?: string,
) => {
  const updatedProperty = { ...property };

  if (iconUrl) {
    updatedProperty.icon_url = iconUrl;
  } else {
    delete updatedProperty.icon_url;
  }

  return updatedProperty;
};

export const getPropertyProvider = (property: PresentationProperty) => {
  return property.provider;
};

export const setPropertyProvider = (
  property: PresentationProperty,
  provider?: string,
) => {
  const updatedProperty = { ...property };

  if (provider) {
    updatedProperty.provider = provider;
  } else {
    delete updatedProperty.provider;
  }

  return updatedProperty;
};

export const getPropertyFileType = (property: PresentationProperty) => {
  return property.file_type;
};

export const setPropertyFileType = (
  property: PresentationProperty,
  fileType?: PresentationPropertyFileType,
) => {
  const updatedProperty = { ...property };

  if (fileType) {
    updatedProperty.file_type = fileType;
  } else {
    delete updatedProperty.file_type;
  }

  return updatedProperty;
};

export const newTextProperty = (): PresentationProperty => ({
  name: '',
  type: 'string',
  multiple: false,
  optional: true,
});

export const newNumberProperty = (): PresentationProperty => ({
  name: '',
  type: 'number',
  optional: true,
});

export const newToggleProperty = (): PresentationProperty => ({
  name: '',
  type: 'boolean',
  optional: true,
  default: false,
});

export const newSelectProperty = (): PresentationProperty => ({
  name: '',
  type: 'selection2',
  optional: true,
  multiple: false,
});

export const newMultiSelectProperty = (): PresentationProperty => ({
  name: '',
  type: 'selection2',
  optional: true,
  multiple: true,
});

export const newButtonGroupProperty = (): PresentationProperty => ({
  name: '',
  type: 'toggleButtonGroup',
  optional: true,
  exclusive: true,
});

export const newColorPickerProperty = (): PresentationProperty => ({
  name: '',
  type: 'color',
  default: '#000000',
});

export const newOAuthProperty = (): PresentationProperty => ({
  name: '',
  type: 'oAuth2',
  optional: true,
});

export const newFileUploadProperty = (): PresentationProperty => ({
  name: '',
  type: 'file',
  optional: true,
  file_type: 'image',
});

export const newProductCatalogProperty = (): PresentationProperty => ({
  name: '',
  type: 'catalog',
  optional: true,
});
